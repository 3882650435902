import React from "react"
import { Helmet } from "react-helmet"

export default function Home() {
    return <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Trumping the Movement!</title>
        </Helmet>
        <h1>Trumping the Movement!</h1>
        <p>A place to explore our President, a very stable genious!</p>
        <div>Coming Soon...Faster than the national plan for handling the Covid Crisis!</div>
        <div>
            <p>In the meantime, make sure you are <a href="https://vote.gov" target="_blank">Registered to Vote!</a></p>
        </div>
    </div>
}
